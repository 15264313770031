import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import { mq } from "../../utils/media-queries"

const References = props => {
  return (
    <article>
      <Container
        css={css`
          padding: 40px 15px 10px 15px;
          ${[mq[2]]} {
            padding: 10px 15px 10px 0;
          }
          li {
            font-size: 14px;
            line-height: 16px;
            text-align: start;
            overflow-wrap: break-word;
            ${[mq[2]]} {
              font-size: 12px;
              line-height: 14px;
            }
          }
        `}
      >
        <Row>
          <Col>
            {props.isDiscussionGuide ? (
              <ol>
                <li>
                  Leukemia & Lymphoma Society. Hodgkin Lymphoma Subtypes. https://www.lls.org/lymphoma/hodgkin-lymphoma/diagnosis/hodgkin-lymphoma-subtypes. Accessed August 11, 2021.
                </li>
                <li>
                  Cancer.Net. Lymphoma - Hodgkin: Diagnosis. https://www.cancer.net/cancer-types/lymphoma-hodgkin/diagnosis. Accessed August 11, 2021.
                </li>
                <li>
                  Mayo Clinic. Lymphoma - Diagnosis & Treatment. https://www.mayoclinic.org/diseases-conditions/lymphoma/diagnosis-treatment/drc-20352642. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Tests for Hodgkin Lymphoma. https://www.cancer.org/cancer/hodgkin-lymphoma/detection-diagnosis-staging/how-diagnosed.html. Accessed August 3, 2021.
                </li>
                <li>
                  Cancer.Net. Lymphoma - Hodgkin: Types of Treatment. https://www.cancer.net/cancer-types/lymphoma-hodgkin/types-treatment. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Hodgkin Lymphoma Stages. https://www.cancer.org/cancer/hodgkin-lymphoma/detection-diagnosis-staging/staging.html. Accessed August 3, 2021.
                </li>
                <li>
                  Cancer.Net. The Oncology Team. https://www.cancer.net/navigating-cancer-care/cancer-basics/cancer-care-team/oncology-team. Accessed August 11, 2021.
                </li>
                <li>
                  American Cancer Society. The Doctor-Patient Relationship. https://www.cancer.org/treatment/finding-and-paying-for-treatment/choosing-your-treatment-team/the-doctor-patient-relationship.html.
                </li>
                <li>
                  American Cancer Society. Seeking a Second Opinion. https://www.cancer.org/treatment/treatments-and-side-effects/choosing-your-treatment-team/seeking-a-second-opinion.html. Accessed August 3, 2021.
                </li>
                <li>
                  Cancer.Net. Making Decisions About Cancer Treatment. https://www.cancer.net/navigating-cancer-care/how-cancer-treated/making-decisions-about-cancer-treatment. Accessed August 3, 2021.
                </li>
                <li>
                  CancerCare. Sources of Financial Assistance. https://www.cancercare.org/publications/62-sources_of_financial_assistance. Accessed October 26, 2021.
                </li>
                <li>
                  American Cancer Society. Late and Long-term Side Effects of Hodgkin Lymphoma Treatment. https://www.cancer.org/cancer/hodgkin-lymphoma/after-treatment/lifestyle-changes.html. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Get Cancer Information Now. https://www.cancer.org/cancer/hodgkin-lymphoma/treating.html. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Treating Classic Hodgkin Lymphoma, by Stage. https://www.cancer.org/cancer/hodgkin-lymphoma/treating/by-stage.html. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Chemotherapy for Hodgkin Lymphoma. https://www.cancer.org/cancer/hodgkin-lymphoma/treating/chemotherapy.html. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. If Cancer Treatments Stop Working. https://www.cancer.org/treatment/treatments-and-side-effects/planning-managing/if-cancer-treatments-stop-working.html. Accessed August 3, 2021.
                </li>
                <li>
                  American Cancer Society. Chemotherapy Side Effects. https://www.cancer.org/treatment/treatments-and-side-effects/treatment-types/chemotherapy/chemotherapy-side-effects.html. Accessed August 3, 2021.
                </li>
                <li>
                  Cancer.Net. Lymphoma - Hodgkin: Coping with Treatment https://www.cancer.net/cancer-types/lymphoma-hodgkin/coping-with-treatment. Accessed August 3, 2021.
                </li>
              </ol>
            ) : null}

            {!props.isDiscussionGuide && !props.isHome ? (
              <ol>
                <li>
                  American Cancer Society. What is Hodgkin Lymphoma? https://www.cancer.org/CANCER/HODGKIN-LYMPHOMA/ABOUT/WHAT-IS-HODGKIN-DISEASE.HTML. Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Signs and Symptoms of Hodgkin Lymphoma. https://www.cancer.org/CANCER/HODGKIN-LYMPHOMA/DETECTION-DIAGNOSIS-STAGING/SIGNS-AND-SYMPTOMS.HTML. Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Key Statistics for Hodgkin Lymphoma. Available at https://www.cancer.org/cancer/types/hodgkin-lymphoma/about/key-statistics.html. Accessed January 24, 2024.
                </li>
                <li>
                  American Cancer Society. Survival rates for Hodgkin lymphoma. https://www.cancer.org/cancer/types/hodgkin-lymphoma/detection-diagnosis-staging/survival-rates.html. Accessed January 24, 2024.
                </li>
                <li>
                  Clinical Medicine Insights: Oncology. Frontline Therapy for Classical Hodgkin Lymphoma by Stage and Prognostic Factors. https://journals.sagepub.com/doi/full/10.1177/1179554917731072. Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Treating Classic Hodgkin Lymphoma by Stage. Available at https://www.cancer.org/CANCER/HODGKIN-LYMPHOMA/TREATING/BY-STAGE.HTML Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Living as a Hodgkin Lymphoma Survivor. Available at HTTPS://WWW.CANCER.ORG/CANCER/HODGKIN-LYMPHOMA/AFTER-TREATMENT/FOLLOW-UP.HTML. Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Chemotherapy at Hodgkin Lymphoma. Available at https://www.cancer.org/CANCER/HODGKIN-LYMPHOMA/TREATING/CHEMOTHERAPY.HTML. Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. Late and Long-term Side Effects of Hodgkin Lymphoma Treatment. Available at HTTPS://WWW.CANCER.ORG/CANCER/HODGKIN-LYMPHOMA/AFTER-TREATMENT/LIFESTYLE-CHANGES.HTML. Accessed October 25, 2022.
                </li>
              </ol>
            ) : null}

            {props.isHome ? (
              <ol>
                <li>
                  Key statistics for Hodgkin lymphoma. American Cancer Society. Revised January 19, 2024. Accessed January 24, 2024. https://www.cancer.org/cancer/types/hodgkin-lymphoma/about/key-statistics.html
                </li>
                <li>
                  What is Hodgkin lymphoma? American Cancer Society. Revised May 1, 2018. Accessed January 24, 2024. https://www.cancer.org/cancer/types/hodgkin-lymphoma/about/what-is-hodgkin-disease.html
                </li>
                <li>
                  Survival rates for Hodgkin lymphoma. American Cancer Society. Revised March 2, 2023. Accessed January 24, 2024. https://www.cancer.org/cancer/types/hodgkin-lymphoma/detection-diagnosis-staging/survival-rates.html
                </li>
                {/* <li>
                  American Cancer Society. Key Statistics for Hodgkin Lymphoma.
                  Available at
                  https://www.cancer.org/cancer/hodgkin-lymphoma/about/key-statistics.html.
                  Accessed October 25, 2022.
                </li>
                <li>
                  American Cancer Society. What is Hodgkin Lymphoma?
                  https://www.cancer.org/CANCER/HODGKIN-LYMPHOMA/ABOUT/WHAT-IS-HODGKIN-DISEASE.HTML.
                  Accessed October 25, 2022.
                </li> */}
              </ol>
            ) : null}
          </Col>
        </Row>
      </Container>
    </article>
  )
}

References.propTypes = {
  isHome: PropTypes.bool,
  isDiscussionGuide: PropTypes.bool,
}

export default References
